import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('admin/manage-account?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('admin/manage-account/show/'+id);
    },    
    store(params) {
        return Service().post('admin/manage-account/store', params);
    },
    update(id, params) {
        return Service().post('admin/manage-account/update/'+id, params);
    },
    delete(id) {
        return Service().post('admin/manage-account/delete/'+id);
    },
    change_status(id, params) {
        return Service().post('admin/manage-account/update_status/'+id, params);
    },
    switch_account(id, params) {
        return Service().post('admin/manage-account/switch-account/'+id, params);
    },
    search_username_sso(params){
        return Service().get('admin/manage-account/get-account-sso',{params});
    }
}